import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
} from "swiper";
/// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
export default function MapSlider() {
  const data = [
    {
      image: "/images/Migration Map_India 2.png",
      title: "India",
    },
    {
      image: "/images/Migration Map_Nepal 2.png",
      title: "Nepal",
    },
    {
      image: "/images/Migration Map_Bangladesh2.png",
      title: "Bangladesh",
    },
  ];
  return (
    <div className="flex flex-col justify-center items-center w-full lg:px-28 md:px-12 px-4">
      <h3 className="md:mb-8 mb-6 text-center uppercase text-darkblue w-full font-bold lg:text-4xl md:text-3xl text-xl">
        MRC currently works in:
      </h3>
      <div className="w-full h-auto relative dotsCircle carouselWrapper">
        <Swiper
          // autoHeight={true}
          modules={[
            Navigation,
            Pagination,
            Scrollbar,
            A11y,
            Autoplay,
            EffectFade,
          ]}
          pagination={{
            clickable: true,
          }}
          spaceBetween={0}
          loop={false}
          slidesPerView={3}
          breakpoints={{
            1280: {
              spaceBetween: 20,
              slidesPerView: 3,
            },
            991: {
              spaceBetween: 20,
              slidesPerView: 3,
            },
            // when window width is >= 768px
            768: {
              spaceBetween: 20,
              slidesPerView: 2.3,
            },
            325: {
              slidesPerView: 1.3,
              spaceBetween: 20,
            },
          }}
          // navigation={{
          //   prevEl: prevRef?.current,
          //   nextEl: nextRef?.current,
          // }}
          speed={500}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          //   effect="fade"
          // onSwiper={setSwiper}
          className="relative"
        >
          {data.map((row, key) => (
            <SwiperSlide key={"map" + key}>
              <div className="flex flex-col lg:gap-8 md:gap-2 gap-0 justify-center items-center w-full">
                <img
                  src={row.image}
                  alt={row.title}
                  className="md:w-full md:h-[500px] w-[360px] h-[360px] object-contain object-center"
                />
                <p className="text-darkblue font-bold text-center lg:text-lg text-sm">
                  {row.title}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
