import * as React from "react";
import { motion } from "framer-motion";

const Paragraph = (props) => (
    <div className="mt-4">
        <p className="mb-0 text-black font-light px-4 text-sm">{props.content}</p>
    </div>
);

export default function ContentPlaceholder(props) {
    return (
        <motion.div
            variants={{ collapsed: { opacity: 0.8 }, open: { opacity: 1 } }}
            transition={{ duration: 0.3 }}
            className="content-placeholder"
        >
            {props.variant === "list" ?
                <ul className="px-6 list-disc pt-4">
                    {props.content.map((row, key) => (
                        <li className="text-sm text-black text-normal mb-2 last:mb-0" key={"goalList" + key}>{row.content}</li>
                    ))}
                </ul>
                :
                <Paragraph content={props.content} />
            }
        </motion.div>
    )
}
