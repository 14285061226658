import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ContentPlaceholder from "./ContentPlaceholder";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";
import { FaMinus } from "@react-icons/all-files/fa/FaMinus";
import { TiArrowSortedDown } from "@react-icons/all-files/ti/TiArrowSortedDown";
import { TiArrowSortedUp } from "@react-icons/all-files/ti/TiArrowSortedUp";

const Accordion = ({
  i,
  expanded,
  setExpanded,
  content,
  title,
  variant,
  children,
}) => {
  // const isOpen = i === expanded;
  const isOpen = true;
  // By using `AnimatePresence` to mount and unmount the contents, we can animate
  // them in and out while also only rendering the contents of open accordions
  return (
    <div className="my-0 last:mb-0 w-full">
      <motion.div
        initial={false}
        animate={{ backgroundColor: isOpen ? "#F1F7FF" : "#F1F7FF" }}
        onClick={() => setExpanded(isOpen ? false : i)}
        className="rounded-lg flex px-4 w-full lg:h-10 h-12 justify-start items-center"
      >
        <p className="text-black font-semibold md:text-base text-sm leading-none md:max-w-[90%] max-w-[85%]">
          {title}
        </p>
        {/* {!isOpen ? (
          <TiArrowSortedDown color="black" size={18} className="ml-auto" />
        ) : (
          <TiArrowSortedUp
            color={"#2C1573"}
            size={18}
            className="ml-auto font-bold"
          />
        )} */}
      </motion.div>
      <AnimatePresence initial={true}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { height: "auto", opacity: 1 },
              collapsed: { height: "auto", opacity: 1 },
            }}
            transition={{ duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }}
            className="mt-2 pl-2"
          >
            {children}
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};

export const AccordionCollapse = (props) => {
  // This approach is if you only want max one section open at a time. If you want multiple
  // sections to potentially be open simultaneously, they can all be given their own `useState`.
  const [expanded, setExpanded] = useState(true);

  return (
    <Accordion
      i={1}
      expanded={expanded}
      setExpanded={setExpanded}
      content={props.content}
      title={props.title}
      variant={props.variant}
      children={props.children}
    />
  );
};

// const accordionIds = [0, 1, 2, 3];
