export const BannerData = [
  {
    id: 1,
    heading: "Implementation",
    icon: "/images/personal-data.svg",
    text: "First, we work to implement last-mile delivery systems, ensuring the most vulnerable migrants have access to existing protections.",
  },
  {
    id: 2,
    heading: "Systems Change",
    icon: "/images/global.svg",
    text: "Second, we change the systems to work better for all migrants by supporting the government to strengthen infrastructure and increasing incentives and accountability for industry actors.",
  },
];
