import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";
import Paragraph from "../utils/Paragraph";
import { motion } from "framer-motion";
import { HiOutlineArrowCircleLeft } from "@react-icons/all-files/hi/HiOutlineArrowCircleLeft";
import { HiOutlineArrowCircleRight } from "@react-icons/all-files/hi/HiOutlineArrowCircleRight";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
} from "swiper";
/// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
export default function BannerSlider() {
  const videoRef = useRef(null);
  const [videoPlay, setVideoPlay] = useState(false);
  const playVideo = () => {
    setVideoPlay(!videoPlay);
    if (videoPlay) {
      videoRef.current.pause();
    } else videoRef.current.play();
  };
  const data = [
    // {
    //   text: "Migrants Resilience Collaborative (MRC) is a grassroots-led multi-stakeholder collaborative of nonprofit, philanthropic, and private sector actors focused on ensuring safety, security, and mobility for vulnerable internal migrants.",
    //   image: "/images/mrc-home-1.jpg",
    // },
    {
      text: "MRC aims to strengthen social protection systems in India and other part of South and Southeast Asia.",
      image: "/images/mrc-home-2.jpg",
    },
  ];
  const [swiper, setSwiper] = React.useState();
  const prevRef = React.useRef();
  const nextRef = React.useRef();

  React.useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);
  return (
    <div className="w-full h-auto relative dotsCircle carouselWrapper">
      {/* <div
        ref={prevRef}
        className="cursor-pointer absolute top-1/2 md:left-4 left-2 z-[10]"
      >
        <HiOutlineArrowCircleLeft className="md:text-4xl text-2xl text-white" />
      </div> */}
      <Swiper
        // autoHeight={true}
        modules={[
          Navigation,
          Pagination,
          Scrollbar,
          A11y,
          Autoplay,
          EffectFade,
        ]}
        pagination={{
          clickable: true,
        }}
        spaceBetween={0}
        loop={false}
        slidesPerView={1}
        // navigation={{
        //   prevEl: prevRef?.current,
        //   nextEl: nextRef?.current,
        // }}
        speed={500}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        effect="fade"
        onSwiper={setSwiper}
        className="relative"
      >
        <SwiperSlide>
          <div className="flex flex-col w-full lg:h-[75vh] md:h-[600px] h-[400px] relative">
            <motion.img
              src={"/images/ted-banner.jpg"}
              alt=""
              className="w-full object-cover object-[50%_20%] h-full overflow-hidden"
            />
            <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-start lg:px-32 md:px-16 px-6 bg-darkblue/20">
              <motion.div className="flex flex-col h-full w-full justify-center items-start lg:w-[400px] md:w-[300px]">
                <h1 className="text-blue font-bold lg:text-4xl md:text-2xl text-xl">
                  A Path to Social Safety for Migrant Workers
                </h1>
                <p className="mt-4 mb-6 text-white font-medium text-base md:text-lg lg:leading-normal leading-tight">
                  Watch Ashif Shaikh, the convener of MRC, share the idea behind
                  our work and how we aim to work with governments, industry,
                  and civil society to support those who build our world
                </p>
                <a
                  href="https://www.ted.com/talks/ashif_shaikh_a_path_to_social_safety_for_migrant_workers?hasSummary=true"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-blue hover:bg-darkblue transition-all duration-300 ease rounded-lg text-center flex items-center justify-center px-4 lg:py-3 py-2 text-white font-medium font-body text-sm md:text-base"
                >
                  Watch The Talk
                </a>
              </motion.div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {/* <div className="relative w-full bg-blue h-full"> */}
          <div className="lg:h-[75vh] md:h-[600px] h-[400px] w-full">
            <video
              ref={videoRef}
              className="h-full w-full object-cover"
              autoPlay
              loop
              muted
              playsInline
              controls
            >
              <source src={"/video.mp4"} type="video/mp4" />
            </video>
            <div
              className={`${
                videoPlay ? "opacity-0 hidden" : "bg-opacity"
              } z-20 flex absolute left-0 top-0 w-full h-full items-center justify-center cursor-pointer transition-all duration-500 group`}
            >
              <img
                src={"/images/video-banner-mrc.png"}
                onClick={playVideo}
                alt="how it works"
                className="object-cover md:object-right object-center w-full h-full"
              />
              <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-start lg:px-32 md:px-16 px-6 bg-darkblue/20">
                <motion.div
                  // initial={{ x: 50, opacity: 8 }}
                  // animate={{ x: 0, opacity: 100 }}
                  // transition={{ duration: 0.5, delay: 0.1 }}
                  className="flex flex-col h-full w-full justify-end items-start"
                >
                  {/* <h1 className="w-full text-white font-bold lg:text-5xl md:text-4xl text-2xl lg:mb-6 mb-2 !leading-snug">
                    Migrants Resilience Collaborative (MRC)
                  </h1> */}
                  <img
                    src={"../images/play-circle.svg"}
                    onClick={playVideo}
                    alt=""
                    className="md:w-16 w-8 transition-all duration-500 ease-in-out transform group-hover:scale-110 absolute top-[40%] left-[50%]"
                  />
                  <p className="bg-darkblue/10 backdrop-blur-[10px] py-4 px-4 md:mb-8 mb-4 rounded-lg text-white font-bold text-base md:text-2xl lg:leading-normal leading-tight">
                    Migrants Resilience Collaborative (MRC) is a grassroots-led
                    multi-stakeholder collaborative of nonprofit, philanthropic,
                    and private sector actors focused on ensuring safety,
                    security, and mobility for vulnerable internal migrants.
                  </p>
                </motion.div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </SwiperSlide>

        {data.map((row, index) => (
          <SwiperSlide key={"atHomeSlide" + index}>
            <div className="flex flex-col w-full lg:h-[75vh] md:h-[600px] h-[400px] relative">
              <motion.img
                src={row.image}
                alt=""
                className="w-full object-cover h-full overflow-hidden"
              />
              <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-start lg:px-32 md:px-16 px-6 bg-darkblue/20">
                <motion.div className="flex flex-col h-full w-full justify-end items-start ">
                  <p className="bg-darkblue/10 backdrop-blur-[10px] py-4 px-4 md:mb-8 mb-4 rounded-lg text-white font-bold text-base md:text-2xl lg:leading-normal leading-tight">
                    {row.text}
                  </p>
                </motion.div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* <div
        ref={nextRef}
        className="cursor-pointer absolute top-1/2 md:right-4 right-2 z-[10]"
      >
        <HiOutlineArrowCircleRight className="md:text-4xl text-2xl text-white" />
      </div> */}
    </div>
  );
}
