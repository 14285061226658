import * as React from "react";
import CountUp from "react-countup";
import Layout from "../components/layout";
import Map from "../utils/Map";
import GallerySlider from "../components/GallerySlider";
import Paragraph from "../utils/Paragraph";
import Button from "../utils/Button";
import H2 from "../utils/H2";
import Seo from "../components/seo";
import BannerSlider from "../components/BannerSlider";
import { FaAirbnb } from "@react-icons/all-files/fa/FaAirbnb";
import { FaUserLock } from "@react-icons/all-files/fa/FaUserLock";
import ReactPlayer from "react-player/youtube";
import CaseStudy from "../components/CaseStudy";
import CounterSection from "../components/CounterSection";
import { AccordionRow } from "../components/Accordion";
import { AccordionCollapse } from "../components/AccordionCollapse";
import { GoalsData } from "../data/goals";
import { BannerData } from "../data/banner-data";
import Counter from "../components/Counter";
import MapSlider from "../components/Pages/Home/MapSlider";
// markup

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" url="https://migrantresilience.org" />
      <div className="w-full rounded-bl-[200px] relative z-0 col-span-1 row-span-1">
        <div className="overflow-hidden w-full h-full">
          <BannerSlider />
        </div>

        <div className="lg:block hidden bg-lightBlue rounded-full w-40 h-40 absolute top-0 -left-16 -z-1 opacity-60" />
        <div className="bg-lightBlue rounded-br-100px w-48 h-48 absolute lg:-bottom-[100px] -bottom-[50px] lg:right-28 right-0 -z-1" />
      </div>
      {/* <div className="lg:grid lg:grid-cols-2 lg:grid-rows-1 flex flex-col flex-col-reverse w-full 2xl:mb-20 lg:mb-[100px] md:mb-20 mb-24 ">
        <div className="w-full flex flex-col justify-end items-start lg:pl-28 md:px-12 px-4 pt-12 lg:h-full h-auto relative z-0 row-span-1 lg:mt-0 lg:mt-0 mt-12">
          <h1 className="w-full text-darkblue font-bold md:text-4xl text-2xl md:mb-6 mb-2">
            Migrants Resilience Collaborative (MRC)
          </h1>
          <Paragraph className="md:mb-8 mb-4">
            Migrants Resilience Collaborative (MRC) is a grassroots-led
            multi-stakeholder collaborative of nonprofit, philanthropic, and
            private sector actors focused on ensuring safety, security, and
            mobility for vulnerable internal migrants.
            <br />
            <br />
            There are over 750 million domestic migrant workers globally,
            including 200 million in India. , Accessible social protection
            systems are a critical strategy to address the vulnerability of
            migrant workers. They are proven to build resilience, mitigate
            distress and break inter-generational cycles of poverty.
            <br />
            <br />
            MRC aims to fix broken social protection delivery systems in India
            and other parts of South and Southeast Asia. This will help ensure
            the resilience and dignity of internal migrants.
            <br />
            <br />
            To achieve this, MRC will implement a two-pronged strategy:
          </Paragraph>
          <div className="grid md:grid-cols-2 grid-cols-1 w-full gap-4 mb-4">
            {BannerData.map((row, key) => (
              <HeaderIconBox key={"headerIconBox" + key} data={row} />
            ))}
          </div>
          <Paragraph className="mb-4">
            By bringing both together over the next 5 years, we aim to build
            resilience through strong safety nets and meaningful access to
            social protections for 10s of millions.
          </Paragraph>
          <img
            src="/images/dot-pattern.png"
            className="absolute bottom-0 md:left-20 left-0 w-80 object-contain opacity-80 -z-1"
          ></img>
        </div>
        <div className="w-full h-full rounded-bl-[200px] relative z-0 col-span-1 row-span-1 lg:h-[650px] md:h-450px h-[400px]">
          <div className="overflow-hidden w-full h-full rounded-bl-[200px]">
            <BannerSlider />
          </div>

          <div className="lg:block hidden bg-lightBlue rounded-full w-40 h-40 absolute top-0 -left-16 -z-1 opacity-60" />
          <div className="bg-lightBlue rounded-br-100px w-48 h-48 absolute lg:-bottom-[100px] -bottom-[50px] lg:right-28 right-0 -z-1" />
        </div>
      </div> */}
      <div className="w-full flex lg:flex-row flex-col items-center lg:pl-28 md:px-12 px-4 py-20 lg:py-16 lg:h-full h-auto relative z-0 lg:gap-20 md:gap-12 gap-6">
        <div className="flex flex-col justify-center w-full">
          <h3 className="md:mb-8 mb-4 lg:text-xl text-lg font-semibold">
            We have a two-pronged strategy:
          </h3>
          <div className="grid md:grid-cols-2 grid-cols-1 w-full gap-6 mb-8">
            {BannerData.map((row, key) => (
              <HeaderIconBox key={"headerIconBox" + key} data={row} />
            ))}
          </div>

          <img
            src="/images/dot-pattern.png"
            className="absolute bottom-0 md:left-20 left-0 w-80 object-contain opacity-80 -z-1"
          ></img>
        </div>
        {/* <img
          src="/images/case-study-1.jpg"
          alt="mrc"
          className="w-[450px] lg:h-[400px] h-[350px] rounded-lg object-cover object-center shrink-0"
        /> */}
      </div>
      <div className="relative mb-20">
        <div className="w-full flex z-0 lg:h-[105%] h-[103%] absolute top-0 right-0">
          <img
            src="/images/blue-dots.png"
            className="-z-1 absolute bottom-4 left-28 w-3/12 h-auto mt-auto object-contain opacity-80"
          ></img>
          <div
            className="flex pl-28 rounded-tl-150px lg:w-9/12 w-11/12 ml-auto"
            style={{ backgroundColor: "#F4F9FF" }}
          />
          <div className="hidden lg:block absolute -bottom-12 right-28 bg-transparent -z-1 rounded-full w-32 h-32 border-6 border-darkblue"></div>
        </div>
        <div className="relative w-full h-auto top-0 left-0 flex flex-col justify-start items-start lg:px-28 md:px-12 px-4">
          {/* <H2 className="lg:max-w-sm mb-8 uppercase">5-year goals</H2> */}
          <h3 className="uppercase text-darkblue text-left w-full font-bold lg:text-4xl md:text-3xl text-xl mb-6">
            5-year goals
          </h3>
          {/* <CounterSection /> */}
          <div className="grid lg:grid-cols-1 lg:grid-rows-1 grid-cols-1 auto-rows-auto gap-8 w-full">
            {GoalsData.map((row, key) => (
              <IconBox data={row} key={"goals" + key} />
            ))}
          </div>
        </div>
      </div>

      {/* <div className="lg:px-28 md:px-12 px-4 py-12">
        <div className="grid gap-32 grid-rows-1 grid-cols-2">
          <div className="w-auto h-372px rounded-3xl relative z-0" style={{ borderRadius: "30px" }}>
            <ReactPlayer url='https://www.youtube.com/watch?v=qWzyHl4D9To' width="100%" height="100%" className="video" />
            <div className="bg-lightBlue rounded-full w-40 h-40 absolute -bottom-20 -right-12 -z-1" />
          </div>
          <div className="flex flex-col justify-center items-center w-full h-auto pr-20 z-0 relative">
            <H2 className="w-full mb-4 text-left">Lorem ipsum dolor sit amet, consectetur</H2>
            <Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris facilisis nibh sit amet purus egestas, vitae auctor nisi pellentesque. Maecenas ut ultricies mauris, id dapibus lacus. Nullam auctor, nisi at dignissim scelerisque, sem justo vestibulum tellus, eget hendrerit ligula orci et lacus. consectetur adipiscing elit. Mauris facilisis nibh sit amet purus egestas, vitae auctor nisi pellentesque. Maecenas ut ultricies mauris,</Paragraph>
            <div className="rounded-br-100px w-40 h-40 absolute top-12 -left-8 -z-1 bg-[#F2F9FF]" />
          </div>
        </div>
      </div> */}
      {/* <div className="lg:px-28 md:px-12 px-4 pb-12 flex flex-col justify-start items-start w-full md:mb-12 mb-4">
        <h3 className="text-darkblue text-left w-full font-bold lg:text-4xl md:text-3xl text-xl mb-6">
          Collaboration &amp; Partnership
        </h3>
        <div className="w-full flex md:flex-row flex-col justify-between md:items-center items-end relative z-0 md:mb-4 mb-6">
          <p className="text-left text-black text-base font-normal md:w-10/12 w-full mb-2 pr-4">
            We've achieved this due to partnerships we've forged with various
            governments, institutions, industry, and collaborations at the
            grassroot level
          </p>
        </div>

        <div className="mb-8 grid md:grid-cols-2 md:grid-rows-1 auto-rows-auto grid-cols-1 w-full gap-4 justify-start items-start">
          <SinglePointOld
            text="Collaborations with State Governments and respective institutions, including the Chhattisgarh and Telangana Labour Department, and the Maharashtra and Madhya Pradesh Food Departments."
            number="5"
          />
          <SinglePointOld
            text="Industry partnerships, including Total Environment, Mahindra Lifespaces Developers Limited, Tata Realty, Godrej Properties and Rohan Builders &  Developers, among others."
            number="12"
          />
        </div>
        <Button type="internalLink" to="/governance">
          Know More
        </Button>
      </div> */}
      {/* <GallerySlider /> */}
      {/* <div className="md:px-12 lg:px-0 px-4 py-12 flex flex-col justify-center items-center w-full my-16 lg:mb-0 lg:mt-28 sectionGradient">
        <H2 className="lg:w-10/12 mb-8 text-center lg:text-3xl">
          <span className="text-blue">130</span> District Migration Resource
          Centers (DMRC) to provide assistance and support to migrant workers in{" "}
          <span className="text-blue">97</span> districts of{" "}
          <span className="text-blue">13</span> states across India.
          <br />
          <span className="flex w-full justify-center items-center">
            Field force:{" "}
            <Counter
              start={1}
              end={1000}
              suffix={"&nbsp;+"}
              className="text-blue"
            />
          </span>
        </H2>
        <Map />
      </div> */}
      {/* <div className="sectionGradient h-auto lg:px-28 md:px-12 px-4 w-full py-20">
        <div className="flex flex-row justify-between items-center mb-16">
          <H2 className="w-auto mb-0 text-left">Our Latest Case Studies</H2>
          <Button type="internalLink">view more</Button>
        </div>
        <div className="grid grid-cols-3 grid-rows-1 gap-8">
          <CaseStudy />
          <CaseStudy />
          <CaseStudy />
        </div>
      </div> */}
      <MapSlider />
    </Layout>
  );
};
const IconBox = (props) => {
  const { data } = props;
  return (
    <div
      className={`w-full overflow-hidden h-full discover bg-white rounded-3xl flex md:flex-row flex-col md:gap-8 gap-4 justify-start md:items-start items-start lg:p-8 p-4  ${props.className}`}
    >
      <div
        className="md:w-32 md:h-32 h-20 w-20 rounded-3xl md:p-8 p-5 flex flex-col shrink-0 justify-center items-center"
        style={{ backgroundColor: "#F1F7FF" }}
      >
        <img src={data.icon} className="w-full object-contain"></img>
      </div>
      <div className="flex md:flex-row flex-col w-full h-full gap-8">
        {/* <p className="text-black lg:mt-8 lg:text-center font-semibold md:text-xl text-md w-full">{props.title}</p> */}
        <AccordionRow title={data.title} content={data.list} variant={"list"} />
        {data.impact && (
          <div className="flex flex-col w-full gap-0 mt-0">
            <AccordionCollapse title={"Achievements so far"}>
              <div className="grid md:grid-cols-1 lg:grid-rows-1 auto-rows-auto grid-cols-1 w-full gap-2 mb-0 justify-start items-start">
                {data.impact.map((row) => (
                  <SinglePoint
                    text={row.text}
                    number={row.number}
                    counter={row.counter}
                    decimals={row.decimals}
                    suffix={row.suffix}
                    prefix={row.prefix}
                  />
                ))}
              </div>
            </AccordionCollapse>
          </div>
        )}

        {/* <p className="text-gray lg:mt-4 text-sm lg:text-center">{props.description}</p> */}
      </div>
    </div>
  );
};

const SinglePoint = (props) => {
  return (
    <div className="flex-col flex justify-start items-start w-full">
      <div className="shrink-0 flex flex-col justify-start items-start rounded-2xl">
        <Counter
          end={props.counter}
          decimals={props.decimals}
          className="lg:text-lg text-md text-[#19bbf9] font-bold"
          suffix={props.suffix}
          prefix={props.prefix}
        />
      </div>
      <p className="text-[#1D3547] lg:text-sm text-sm font-normal w-full md:leading-tight">
        {props.text}
      </p>
    </div>
  );
};
const SinglePointOld = (props) => {
  return (
    <div className="lg:flex-inline flex justify-start items-start w-full">
      <div className="md:w-32 md:h-28 h-20 w-28 bg-[#F4F9FF] shrink-0 flex flex-col justify-center items-center rounded-2xl">
        {props.icon ? (
          <img src={props.icon} className="w-10 object-contain mb-0"></img>
        ) : (
          <Counter
            end={props.number}
            start={1}
            duration={2.5}
            className="lg:text-3xl md:text-lg text-md text-[#19bbf9] font-bold"
          />
        )}
      </div>
      <p className="text-[#1D3547] lg:text-base text-sm font-normal ml-4 w-full pr-4 md:leading-tight">
        {props.text}
      </p>
    </div>
  );
};
const HeaderIconBox = ({ data }) => {
  const { icon, text, heading } = data;
  return (
    <div
      className="w-full flex flex-row justify-start items-start gap-4 p-4 rounded-2xl"
      style={{ backgroundColor: "#F1F7FF" }}
    >
      <div className="lg:h-full h-20 lg:w-24 w-20 rounded-2xl p-5 flex flex-col shrink-0 justify-center items-center bg-white">
        <img src={icon} className="w-full object-contain" />
      </div>
      <div className="flex flex-col w-full">
        <p className="lg:text-lg text-base text-darkblue font-bold">
          {heading}
        </p>
        <p className="lg:text-base text-sm text-body mb-0">{text}</p>
      </div>
    </div>
  );
};
export default IndexPage;
